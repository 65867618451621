import React, {useState, useRef, useEffect} from "react"
import { Link } from 'gatsby'
import './styles.scss'
import playBtn from '../../../svg/play.svg'
import stopBtn from '../../../svg/stop.svg'
import fullScreenBtn from '../../../svg/full-screen.svg'
import poster from '../../../images/speakeasy/theodor-nymark/theodor-nymark-poster.jpg'
import envelope from '../../../images/speakeasy/theodor-nymark/envelope.svg'
import headerLines from '../../../images/speakeasy/theodor-nymark/header_lines.svg'

const videoLink = "https://videos.ctfassets.net/yon2u6qy3zpn/4IzPA3McRe8xcWStZbTQoB/1c5a5c159b0829fde16967462d38f522/videoessay_dearlandscape_Final.mp4";

const VideoSection = ({content}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [isEnvelopeExpanded, setIsEnvelopeExpanded] = useState(false)
  const [envelopeLabel, setEnvelopeLabel] = useState('OPEN')
  const videoEl = useRef(null)

  const handleEnvelopeExpand = () => {
    setIsEnvelopeExpanded(!isEnvelopeExpanded);
    if(isEnvelopeExpanded) {
      setEnvelopeLabel('OPEN');
    } else {
      setEnvelopeLabel('CLOSE');
    }
  }

  const openFullscreen = () => {
    const elem = videoEl.current

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  const handleClick = () => {
    videoEl.current.muted = false
    setClicked(true)
    setIsPlaying(!isPlaying)
  }

  useEffect(() => {
    isPlaying ? videoEl.current.play() : videoEl.current.pause()
  }, [isPlaying])

  return (
    <section className="theodor-nymark-video">
      <img className="theodor-nymark-video__header-lines" src={headerLines}/>
      <div className="video-wrapper">
        <video
          preload='auto'
          poster={poster}
          loop autoPlay muted
          ref={videoEl}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onClick={handleClick}
        >
          <source src={videoLink} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>

        <img className="full-screen-button" src={fullScreenBtn} onClick={openFullscreen} onMouseEnter={() => setOnHover(true)}/>

        <img className="button" style={{visibility: onHover ? 'visible' : 'hidden'}} src={isPlaying ? stopBtn : playBtn}/>
        <img className="button" style={{display: clicked ? 'none' : 'block'}} src={playBtn}/>
      </div>
      <div className="footer">
        <div className={`envelope ${isEnvelopeExpanded ? 'envelope--expanded': ''}`}>
            <div className="label">{envelopeLabel}</div>
            <img className="icon" src={envelope} onClick={handleEnvelopeExpand}/>
            <div className="description" dangerouslySetInnerHTML={{ __html: content.description }} />
          </div>
        <div className="info">
          <div className="exit"><Link to="/speakeasy">Exit</Link></div>
          <div className="title">
            <div>THEODOR NYMARK&nbsp;</div>
            <div>Dear Landscape,</div>
          </div>
          <div className="date">
            <div>9.22-11.6</div>
            <div className="dot">.</div>
            <div>2022</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoSection
