import React, { useState } from "react"
import './styles.scss'
import Img from 'gatsby-image'
import rightArrow from '../../../svg/right-arrow.svg';
import ImageModal from "../image-modal/ImageModal";

const ImageSection = ({content}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    document.body.style.overflow = 'hidden';
    setIsModalOpen(true);
  }

  const handleModalClose = () => {
    document.body.style.overflow = 'auto';
    setIsModalOpen(false);
  }

  return (
    <section className={`theodor-nymark-image-section ${isModalOpen ? '' : `theodor-nymark-image-section--${content.class}`}`}>
      <div className="theodor-nymark-image-section__image-wrapper" onClick={handleModalOpen}>
        <Img
          className="image"
          fluid={content.images[0].path.childImageSharp.fluid}
        />
      </div>
      <div className="info">
        <div className="work_info">
          <div className='info__name'>{content.artist_name}</div>
          <div className='info__title' dangerouslySetInnerHTML={{ __html: content.work_title }}></div>
          <div>{content.work_material}</div>
          <div>{content.work_size}</div>
        </div>
        <div className="inquery"><a href="mailto:hello@rainraingallery.com" target='_blank'>Inquire</a></div>
      </div>

      {content.link ?
        <div className='link'>
          <a href={content.link.url} target='_blank'>
            {content.link.text}<span><img src={rightArrow}/></span>
          </a>
        </div> :
        null
      }

      <ImageModal
        isVisible={isModalOpen}
        images={content.images}
        handleModalClose={handleModalClose}
        title={content.work_title}
      />
    </section>
  )
}

export default ImageSection