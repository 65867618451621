import React from "react"
import './styles.scss'

const TextSection = ({content}) => {
  return (
    <div className={`theodor-nymark-text-section theodor-nymark-text-section--${content.class} ${content.line_bg_type ? 'bg' : ''} ${content.line_bg_type ? content.line_bg_type : ''}`}>
      <div className="text">{content.text}</div>
    </div>
  )
}

export default TextSection
