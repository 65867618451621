import React, { useState } from "react"
import './styles.scss'
import Img from 'gatsby-image'
import closeIcon from '../../../images/speakeasy/theodor-nymark/close.svg';

const ImageModal = props => {
  const {title, images, isVisible, handleModalClose} = props;
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  return (
    <section className="theodor-nymark-image-modal" style={{display: isVisible ? 'block' : 'none'}}>
      <div className="theodor-nymark-image-modal__inner">
        <div className="theodor-nymark-image-modal__inner-left">
          <Img
            className="theodor-nymark-image-modal__primary-image"
            fluid={images[activeImageIndex].path.childImageSharp.fluid}
          />
          {
            activeImageIndex === 0 ?
            <div className="theodor-nymark-image-modal__title" dangerouslySetInnerHTML={{ __html: title }}></div> :
            <div className="theodor-nymark-image-modal__title">Detail</div>
          }
        </div>
        <div className="theodor-nymark-image-modal__inner-right">
          {
            images.map((image, index) => (
              <div className={`theodor-nymark-image-modal__thumbnail-wrapper ${index == activeImageIndex ? 'is-active' : ''}`} onClick={() => setActiveImageIndex(index)}>
                <Img
                  className="theodor-nymark-image-modal__thumbnail"
                  fluid={image.path.childImageSharp.fluid}
                />
              </div>
            ))
          }
        </div>
      </div>
      <img
        className="theodor-nymark-image-modal__close"
        src={closeIcon}
        onClick={handleModalClose}
      />
    </section>
  )
}

export default ImageModal