import React, {useState} from "react"
import './styles.scss'
import Img from 'gatsby-image'

const ProfileSection = ({content}) => {
  return (
    <section className="theodor-nymark-profile-section">
      <div className="wrapper">
        <div className="">
          <div className="title">{content.profileTitle}</div>
          <div className="content-inner">
            <div className="description" dangerouslySetInnerHTML={{ __html: content.profileDescription }}></div>
            <Img
              className="image"
              fluid={content.profileImage.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProfileSection
